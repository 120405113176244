import { useEffect, useState } from "react";
import styles from "./styles.module.css";
import { Table, Spin, Button, Tooltip } from "antd";
import { DatePickerPopoverMount } from "../../components/DatePickerPopoverMount";
import { setOpenInfo } from "../../store/user";
import { InfoModal } from "../../components/InfoModal";
import {
  convertDateToISO861,
  convertToLocalDate,
  dateToWeekdayAndWeekNumber,
} from "../../utils/utils";
import { useDispatch, useSelector } from "react-redux";
import { clearErrorStudio } from "../../store/studios";
import { getStudioData, getErrorStudio } from "../../store/studios/selectors";
import { getOpenInfoSelector } from "../../store/user/selectors";
import { getPlanning, clearError } from "../../store/planning";
import {
  getPlanningData,
  getPlanningLoading,
  getError,
  getOpenPlanningCreateSelector,
  getOpenPlanningEditSelector,
  getSuccessEdit,
  getSuccessCreate,
} from "../../store/planning/selectors";
import { CreatePlanningModal } from "../../components/CreatePlanningModal";
import { EditPlanningModal } from "../../components/EditPlanningModal";
import displayErrors from "../../utils/displayErrors";

export const PlanningPage = () => {
  const dispatch = useDispatch();
  const studioData = useSelector(getStudioData);
  const error = useSelector(getError);
  const isLoading = useSelector(getPlanningLoading);
  const errorStudio = useSelector(getErrorStudio);
  const planningData = useSelector(getPlanningData);
  const openPlanningCreate = useSelector(getOpenPlanningCreateSelector);
  const openPlanningEdit = useSelector(getOpenPlanningEditSelector);
  const openSuccessEdit = useSelector(getSuccessEdit);
  const openSuccessCreate = useSelector(getSuccessCreate);
  const [width, setWidth] = useState(window.innerWidth);
  const [dateAfterFilter, setDateAfterFilter] = useState(null);
  const [openDate, setOpenDate] = useState(false);
  const [viewDataFilter, setViewDataFilter] = useState(null);
  const openInfo = useSelector(getOpenInfoSelector);

  const columns = [
    createColumn(
      "День",
      "day",
      (_, row) => {
        return row && dateToWeekdayAndWeekNumber(row);
      },
      100
    ),
    createColumn(
      "Выполнение факт",
      "smart_plan_persent",
      (v, row) => {
        return (
          v && (
            <div
              style={{
                backgroundColor: `#${row?.smart_plan_persent_color}`,
                width: 53,
                height: 22,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 5,
                fontWeight: 700,
              }}
            >
              {v}%
            </div>
          )
        );
      },
      100
    ),
    createColumn(
      "Выполнение прогноз",
      "smart_plan_exp",
      (v, row) => {
        return (
          v && (
            <div
              style={{
                backgroundColor: `#${row?.smart_plan_exp_color}`,
                width: 53,
                height: 22,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 5,
                fontWeight: 700,
              }}
            >
              {v}%
            </div>
          )
        );
      },
      100
    ),
    createColumn(
      "Дневной план",
      "smart_plan",
      (v) => {
        return v && `${v?.toLocaleString("ru-RU")} р.`;
      },
      100
    ),
    createColumn(
      "Дневной факт",
      "day_result",
      (v) => {
        return v && `${v?.toLocaleString("ru-RU")} р.`;
      },
      100
    ),
    createColumn(
      "Прогноз",
      "expectations",
      (v) => {
        return `${v?.toLocaleString("ru-RU")} р.`;
      },
      100
    ),
    createColumn(
      "Созданные записи",
      "day_forecast",
      (v) => {
        return `${v?.toLocaleString("ru-RU")} р.`;
      },
      100
    ),
    createColumn(
      "Количество полных смен",
      "slots",
      (v) => {
        return Math.round(v * 10) / 10;
      },
      100
    ),
    createColumn(
      "Количество часов",
      "hours",
      (v) => {
        return Math.round(v * 10) / 10;
      },
      100
    ),
    createColumn(
      "Разбивка по специализациям",
      "specialisation",
      (v) => {
        return (
          <div style={{ display: "flex", flexDirection: "column", gap: 7 }}>
            {v.map((item) => {
              return (
                <Tooltip
                  color="#FFFFFF"
                  title={<span style={{ color: "#4B4F55" }}>{item.name}</span>}
                >
                  <div
                    style={{
                      backgroundColor: "#7ADA9D33",
                      padding: "4px 14px",
                      borderRadius: 5,
                      width: 156,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      textAlign: "center",
                    }}
                  >
                    {item.name}
                  </div>
                </Tooltip>
              );
            })}
          </div>
        );
      },
      200
    ),
    createColumn(
      <p style={{ color: "#777676", margin: 0 }}>Прогноз</p>,
      "specialisation",
      (v) => {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              gap: 15,
            }}
          >
            {v.map((item) => {
              return (
                <span>
                  {item?.summ_expectation?.toLocaleString("ru-RU") || 0} р.
                </span>
              );
            })}
          </div>
        );
      },
      100
    ),
    createColumn(
      <p style={{ color: "#777676", margin: 0 }}>Факт</p>,
      "specialisation",
      (v) => {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              gap: 15,
            }}
          >
            {v.map((item) => {
              return (
                <span>{item?.sum_fact?.toLocaleString("ru-RU") || 0} р.</span>
              );
            })}
          </div>
        );
      },
      100
    ),
    createColumn(
      <p style={{ color: "#777676", margin: 0 }}>Смены</p>,
      "specialisation",
      (v) => {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              gap: 15,
            }}
          >
            {v.map((item) => {
              return <span>{Math.round(item.sum_slots * 10) / 10 || 0}</span>;
            })}
          </div>
        );
      },
      100
    ),
    createColumn(
      <p style={{ color: "#777676", margin: 0 }}>Часы</p>,
      "specialisation",
      (v) => {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              gap: 15,
            }}
          >
            {v.map((item) => {
              return <span>{Math.round(item.summ_hours * 10) / 10}</span>;
            })}
          </div>
        );
      },
      100
    ),
  ];

  function createColumn(title, key, render, width, align) {
    return { title, key, dataIndex: key, render, width, align };
  }

  const handleChangeDateAfter = (e) => {
    if (e?.$d) {
      const firstDayOfMonth = new Date(
        e?.$d.getFullYear(),
        e?.$d.getMonth(),
        1
      );
      const monthName = firstDayOfMonth.toLocaleString("ru-RU", {
        month: "long",
      });

      setDateAfterFilter(firstDayOfMonth?.toLocaleDateString("ru-RU"));
      setViewDataFilter(monthName.charAt(0).toUpperCase() + monthName.slice(1));
    } else {
      setViewDataFilter(null);
      setDateAfterFilter(null);
    }
  };

  const fetchData = () => {
    let filters = false;

    if (dateAfterFilter) {
      filters = {
        mon_start_date: convertDateToISO861(dateAfterFilter),
      };
    }

    // if (pagination && nextPage) {
    //   const urlParams = new URLSearchParams(nextPage.split("?")[1]);
    //   offsetValue = urlParams.get("offset");
    // }

    dispatch(clearErrorStudio());
    dispatch(clearError());

    dispatch(
      getPlanning({
        studio_y_id:
          JSON.parse(sessionStorage.getItem("studio"))?.y_id ||
          studioData?.y_id,
        date: filters,
      })
    );
  };

  useEffect(() => {
    if (studioData && dateAfterFilter) {
      const timerRefresh = setTimeout(fetchData, 0);
      return () => {
        clearTimeout(timerRefresh);
      };
    }
  }, [dateAfterFilter, studioData, openSuccessEdit, openSuccessCreate]);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
  }, [width]);

  useEffect(() => {
    const currentDate = new Date();
    const firstDayOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
    const monthName = firstDayOfMonth.toLocaleString("ru-RU", {
      month: "long",
    });

    setDateAfterFilter(convertToLocalDate(firstDayOfMonth).split(" ")[0]);
    setViewDataFilter(monthName.charAt(0).toUpperCase() + monthName.slice(1));
  }, []);

  return (
    <>
      <>
        <div
          style={{
            display: "flex",
            gap: width > 640 ? 12 : 9,
            position: "relative",
            paddingBottom: width > 640 ? 25 : 16,
          }}
        >
          <DatePickerPopoverMount
            openDate={openDate}
            setOpenDate={setOpenDate}
            viewDataFilter={viewDataFilter}
            dateAfterFilter={dateAfterFilter}
            handleChangeDateAfter={handleChangeDateAfter}
          />
        </div>
      </>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            height: "100vh",
            justifyContent: "center",
          }}
        >
          <Spin size="large" />
        </div>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              maxWidth: 600,
              gap: 14,
              marginBottom: 28,
            }}
          >
            <div
              style={{
                display: "flex",
                gap: 10,
                flexDirection: "column",
                alignItems: "flex-end",
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: 9,
                }}
              >
                <div
                  style={{
                    fontSize: 14,
                    fontWeight: 500,
                  }}
                >
                  Факт:
                </div>
                <div
                  style={{
                    backgroundColor: "#B7E4C8",
                    fontSize: 12,
                    fontWeight: 700,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    minWidth: 102,
                    height: 22,
                    borderRadius: 5,
                    padding: "3px 0",
                  }}
                >
                  {planningData?.summary?.fact?.toLocaleString("ru-RU")}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  gap: 9,
                }}
              >
                <div
                  style={{
                    fontSize: 14,
                    fontWeight: 500,
                  }}
                >
                  План:
                </div>
                <div
                  style={{
                    backgroundColor: "#B7E4C8",
                    fontSize: 12,
                    fontWeight: 700,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    minWidth: 102,
                    height: 22,
                    borderRadius: 5,
                    padding: "3px 0",
                  }}
                >
                  {planningData?.summary?.plan?.toLocaleString("ru-RU")}
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                gap: 10,
                flexDirection: "column",
                alignItems: "flex-end",
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: 9,
                }}
              >
                <div
                  style={{
                    fontSize: 14,
                    fontWeight: 500,
                  }}
                >
                  Текущий:
                </div>
                <div
                  style={{
                    backgroundColor: `#${planningData?.summary?.current_result_color}`,
                    fontSize: 12,
                    fontWeight: 700,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: 53,
                    height: 22,
                    borderRadius: 5,
                  }}
                >
                  {planningData?.summary?.current_result
                    ? planningData?.summary?.current_result
                    : 0}
                  %
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  gap: 9,
                }}
              >
                <div
                  style={{
                    fontSize: 14,
                    fontWeight: 500,
                  }}
                >
                  Прогноз:
                </div>
                <div
                  style={{
                    backgroundColor: `#${planningData?.summary?.forecast_result_color}`,
                    fontSize: 12,
                    fontWeight: 700,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: 53,
                    height: 22,
                    borderRadius: 5,
                  }}
                >
                  {planningData?.summary?.forecast_result
                    ? planningData?.summary?.forecast_result
                    : 0}
                  %
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                gap: 10,
                flexDirection: "column",
                alignItems: "flex-end",
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: 9,
                }}
              >
                <div
                  style={{
                    fontSize: 14,
                    fontWeight: 500,
                  }}
                >
                  До плана:
                </div>
                <div
                  style={{
                    backgroundColor: `#${planningData?.summary?.for_plan_color}`,
                    fontSize: 12,
                    fontWeight: 700,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    minWidth: 102,
                    height: 22,
                    borderRadius: 5,
                    padding: "3px 0",
                  }}
                >
                  {planningData?.summary?.for_plan
                    ? planningData?.summary?.for_plan?.toLocaleString("ru-RU")
                    : 0}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  gap: 9,
                }}
              >
                <div
                  style={{
                    fontSize: 14,
                    fontWeight: 500,
                  }}
                >
                  Обеспечено:
                </div>
                <div
                  style={{
                    backgroundColor: `#${planningData?.summary?.for_plan_forecast_color}`,
                    fontSize: 12,
                    fontWeight: 700,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    minWidth: 102,
                    height: 22,
                    borderRadius: 5,
                    padding: "3px 0",
                  }}
                >
                  {planningData?.summary?.for_plan_forecast
                    ? planningData?.summary?.for_plan_forecast?.toLocaleString(
                        "ru-RU"
                      )
                    : 0}
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              marginBottom: 10,
              fontSize: 12,
              fontWeight: 300,
              marginLeft: 5,
            }}
          >
            Показано {planningData?.data?.length || 0} из{" "}
            {planningData?.data?.length || 0}
          </div>
          <div
            style={{
              maxHeight: "calc(100vh - 290px)",
              width: "100%",
              overflowY: "auto",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {(error || errorStudio) && displayErrors(error || errorStudio)}
            <Table
              columns={columns}
              rowKey={(obj) => obj.id}
              size="small"
              pagination={false}
              className={`${styles.table} ${styles.table_header}`}
              dataSource={planningData?.data || []}
              rowClassName={(record) => {
                return record.weekend ? styles.rowTable : "";
              }}
              // onRow={() => ({
              //   onClick: (evt) => {
              //     evt.stopPropagation();
              //   },
              // })}
            />
          </div>
        </>
      )}
      {openPlanningCreate && (
        <CreatePlanningModal
          openPlanningCreate={openPlanningCreate}
          dateAfter={dateAfterFilter}
          dateBefore={planningData?.data?.[planningData.data.length - 1]?.day}
        />
      )}
      {openPlanningEdit && (
        <EditPlanningModal
          openPlanningEdit={openPlanningEdit}
          dateAfter={dateAfterFilter}
          dateBefore={planningData?.data?.[planningData.data.length - 1]?.day}
          plan={planningData?.summary}
        />
      )}
      {openInfo && (
        <InfoModal
          openModal={openInfo}
          setOpenModal={setOpenInfo}
          title={"Информация по планированию"}
          text={"Тут будет информация"}
        />
      )}
    </>
  );
};
