import { useState, useEffect } from "react";
import { Button, Col, Drawer, Form, InputNumber, Row, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import displayErrors from "../../utils/displayErrors";
import {
  postPlanning,
  clearSuccessCreate,
  clearError,
  setOpenPlanningCreate,
} from "../../store/planning";
import {
  getSuccessCreate,
  getError,
  getPlanningLoadingCreate,
} from "../../store/planning/selectors";
import { SuccessModalSide } from "../SuccessModalSide";
import { throwFormError, convertDateToISO861 } from "../../utils/utils";
import { getStudioData } from "../../store/studios/selectors";
import { LoaderModal } from "../LoaderModal";

export const CreatePlanningModal = ({
  openPlanningCreate,
  dateAfter,
  dateBefore,
}) => {
  const [formCreatePlanning] = Form.useForm();
  const dispatch = useDispatch();
  const openSuccessCreate = useSelector(getSuccessCreate);
  const [width, setWidth] = useState(window.innerWidth);
  const studio = useSelector(getStudioData);
  const error = useSelector(getError);
  const isLoader = useSelector(getPlanningLoadingCreate);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
  }, [width]);

  return (
    <Drawer
      styles={{
        body: { padding: 0 },
      }}
      open={openPlanningCreate}
      onCancel={() => {
        dispatch(clearError());
        dispatch(clearSuccessCreate());
        dispatch(setOpenPlanningCreate());
      }}
      onClose={() => {
        dispatch(clearError());
        dispatch(clearSuccessCreate());
        dispatch(setOpenPlanningCreate());
      }}
      footer={null}
      title={
        <Typography.Title style={{ margin: 0 }} level={3}>
          Месячный план
        </Typography.Title>
      }
      width={500}
      maskClosable={true}
    >
      {isLoader && <LoaderModal />}
      {openSuccessCreate ? (
        <SuccessModalSide
          close={() => {
            dispatch(clearError());
            dispatch(clearSuccessCreate());
            dispatch(setOpenPlanningCreate());
          }}
          title="Месячный план успешно добавлен!"
        />
      ) : (
        <div
          style={{
            padding: width > 640 ? "0 64px 20px" : "0 24px 20px",
          }}
        >
          <Form
            style={{ marginTop: "20px" }}
            layout="vertical"
            form={formCreatePlanning}
            onFinish={(data) => {
              data.studio = studio?.id;
              data.date_from = convertDateToISO861(dateAfter);
              data.date_to = dateBefore;

              dispatch(
                postPlanning({
                  studio_y_id: studio?.y_id,
                  planning: data,
                })
              ).then((state) => {
                if (state?.payload?.errors) {
                  throwFormError(formCreatePlanning, state?.payload);
                }
              });
            }}
          >
            {error && displayErrors(error)}

            <Form.Item name="target" label="Сумма">
              <InputNumber
                placeholder="Введите сумму"
                style={{ borderRadius: 2, width: "100%" }}
              />
            </Form.Item>

            <Row gutter={24}>
              <Col span={12}>
                <Button
                  style={{ width: "100%", borderRadius: 2 }}
                  onClick={() => {
                    dispatch(setOpenPlanningCreate());
                    dispatch(clearSuccessCreate());
                    dispatch(clearError());
                  }}
                >
                  Отмена
                </Button>
              </Col>
              <Col span={12}>
                <Button
                  style={{
                    width: "100%",
                    borderRadius: 2,
                    backgroundColor: "#76CEC9",
                  }}
                  htmlType="submit"
                  type="primary"
                >
                  Сохранить
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      )}
    </Drawer>
  );
};
