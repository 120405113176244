import { useEffect, useState, useRef } from "react";
import styles from "./styles.module.css";
import {
  Input,
  Button,
  Table,
  Badge,
  Select,
  Space,
  Spin,
  Row,
  Col,
  Tooltip,
  Avatar,
} from "antd";
import { DatePickerPopover } from "../../components/DatePickerPopover";
import { ModalComponent } from "../../components/ModalComponent";
import { setOpenInfo } from "../../store/user";
import { InfoModal } from "../../components/InfoModal";
import {
  CloseOutlined,
  SearchOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import {
  isInclude,
  convertToLocalDate,
  getDaysWord,
  convertDateToISO861,
  selectDropdown,
  updateFilters,
  updateFiltersArr,
} from "../../utils/utils";
import filters from "../../images/filters.svg";
import iconNotSuccessful from "../../images/iconNotSuccessful.svg";
import iconSuccessful from "../../images/iconSuccessful.svg";
import { useDispatch, useSelector } from "react-redux";
import { clearErrorStudio } from "../../store/studios";
import {
  getStudioData,
  getStudioLoading,
  getErrorStudio,
} from "../../store/studios/selectors";
import { getOpenInfoSelector } from "../../store/user/selectors";
import {
  getFails,
  clearError,
  getTypeDropDown,
  deleteFail,
  clearSuccess,
} from "../../store/fails";
import { getStaffDropDown } from "../../store/staff";
import { getStaffDropDownSelector } from "../../store/staff/selectors";
import {
  getFailsData,
  getFailsLoading,
  getError,
  getTypeDropDownSelector,
  getOpenFailsSelector,
  getSuccess,
  getSuccessCreate,
  getSuccessEdit,
  getFailsNextPage,
} from "../../store/fails/selectors";
import displayErrors from "../../utils/displayErrors";
import { CreateFailsModal } from "../../components/CreateFailsModal";
import { EditFailsModal } from "../../components/EditFailsModal";

export const Complaints = () => {
  const dispatch = useDispatch();
  const studioData = useSelector(getStudioData);
  const error = useSelector(getError);
  const errorStudio = useSelector(getErrorStudio);
  const failsData = useSelector(getFailsData);
  const [width, setWidth] = useState(window.innerWidth);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedGuarantee, setSelectedGuarantee] = useState(null);
  const [selectedStaff, setSelectedStaff] = useState([]);
  const [selectedType, setSelectedType] = useState([]);
  const [includeStaff, setIncludeStaff] = useState(true);
  const [includeType, setIncludeType] = useState(true);
  const [includeStatus, setIncludeStatus] = useState(true);
  const [includeGuarantee, setIncludeGuarantee] = useState(true);
  const [fileSearch, setFileSearch] = useState("");
  const [filtersArray, setFiltersArray] = useState([]);
  const [openFiltersRow, setOpenFiltersRow] = useState(false);
  const [dateAfterFilter, setDateAfterFilter] = useState(null);
  const [dateBeforeFilter, setDateBeforeFilter] = useState(null);
  const [openDate, setOpenDate] = useState(false);
  const [viewDataFilter, setViewDataFilter] = useState(null);
  const isLoadingStudio = useSelector(getStudioLoading);
  const isLoadingFails = useSelector(getFailsLoading);
  const staffList = useSelector(getStaffDropDownSelector);
  const typeList = useSelector(getTypeDropDownSelector);
  const successCreate = useSelector(getSuccessCreate);
  const successEdit = useSelector(getSuccessEdit);
  const openFailsCreate = useSelector(getOpenFailsSelector);
  const openInfo = useSelector(getOpenInfoSelector);
  const [openFailsEdit, setOpenFailsEdit] = useState(false);
  const [openFailsDelete, setOpenFailsDelete] = useState(false);
  const success = useSelector(getSuccess);
  const [needToLoad, setNeedToLoad] = useState(false);
  const nextPage = useSelector(getFailsNextPage);
  const lastItem = useRef();
  const observer = useRef();

  const columns = [
    createColumn(
      "Дата и время",
      "request_datetime",
      (v) => {
        let value = convertToLocalDate(v);
        const [date, time] = value.split(" ");
        return (
          <Space direction="vertical" style={{ gap: 0 }}>
            <span style={{ color: "#4B4F55" }}>{date}</span>
            <span style={{ color: "#7A7D86" }}>{time}</span>
          </Space>
        );
      },
      70
    ),
    createColumn(
      "Клиент",
      "client",
      (v) => {
        return (
          <Row>
            <Col style={{ fontSize: "12px" }}>{v?.y_name}</Col>
            <Col style={{ fontSize: "12px" }}>
              {v?.y_phone.slice(0, 3) + "****" + v?.y_phone.slice(-4)}
            </Col>
          </Row>
        );
      },
      100
    ),
    createColumn(
      "Mастера",
      "master",
      (_, row) => {
        return (
          <div style={{ display: "flex", gap: 12, alignItems: "center" }}>
            <Avatar
              style={{
                width: 25,
                minWidth: 25,
                height: 25,
              }}
              src={row?.master?.y_avatar}
            />
            <Space direction="vertical" style={{ gap: 0 }}>
              <span style={{ textWrap: "nowrap" }}>{row?.master?.y_name}</span>
              <span
                style={{
                  color: "#8B8B8B",
                  fontSize: 12,
                  lineHeight: "14.52px",
                  textWrap: "nowrap",
                }}
              >
                {row?.master?.y_specialization}
              </span>
            </Space>
          </div>
        );
      },
      100
    ),
    createColumn(
      "Тип",
      "fail_type",
      (v) => {
        return v.map((item) => {
          return (
            <Row key={item?.id}>
              <Col style={{ fontSize: "12px", textWrap: "nowrap" }}>
                {item?.name}
              </Col>
            </Row>
          );
        });
      },
      100
    ),
    createColumn(
      "Прошло дней",
      "created_at",
      (v) => {
        const createdAt = new Date(v);
        const currentDate = new Date();
        const diffTime = Math.abs(currentDate - createdAt);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        return (
          <span>
            {diffDays} {getDaysWord(diffDays)}
          </span>
        );
      },
      100
    ),
    createColumn(
      "Комментарий",
      "comment",
      (v) => {
        return (
          <Tooltip
            color="#FFFFFF"
            title={<span style={{ color: "#4B4F55" }}>{v}</span>}
          >
            <p className={styles.commentText}>{v}</p>
          </Tooltip>
        );
      },
      100
    ),
    createColumn(
      "Записали",
      "repair_record",
      (v) => {
        return v ? (
          <img src={iconSuccessful} alt="Успешно" />
        ) : (
          <img src={iconNotSuccessful} alt="Не успешно" />
        );
      },
      100
    ),
    createColumn(
      "Фото",
      "photos",
      (_, v) => {
        return v?.photos?.length > 0 ? (
          <img src={iconSuccessful} alt="Успешно" />
        ) : (
          <img src={iconNotSuccessful} alt="Не успешно" />
        );
      },
      100
    ),
    createColumn(
      "Стоимость переделки",
      "repair_cost",
      (v) => {
        if (v) {
          return <p style={{ textWrap: "nowrap" }}>{v} p.</p>;
        }
      },
      100
    ),
    createColumn(
      "Сумма скидки",
      "loyal_cost",
      (v) => {
        if (v) {
          return <p style={{ textWrap: "nowrap" }}>{v} p.</p>;
        }
      },
      100
    ),
    createColumn(
      "Клиент вернулся",
      "is_returned",
      (v) => {
        return v ? (
          <img src={iconSuccessful} alt="Успешно" />
        ) : (
          <img src={iconNotSuccessful} alt="Не успешно" />
        );
      },
      100
    ),
    createColumn(
      "",
      "action",
      (_, v) => {
        return (
          <Tooltip
            color="#FFFFFF"
            title={<span style={{ color: "#4B4F55" }}>Удалить жалобу</span>}
          >
            <DeleteOutlined
              onClick={(evt) => {
                evt.stopPropagation();
                setOpenFailsDelete({ open: true, data: v });
              }}
              className={styles.icon}
            />
          </Tooltip>
        );
      },
      50
    ),
  ];

  const guarantee = [
    { name: "Гарантийный случай", bol: true },
    { name: "Негарантийный случай", bol: false },
  ];

  const status = [
    { name: "Клиент вернулся", bol: true },
    { name: "Клиент не вернулся", bol: false },
  ];

  function createColumn(title, key, render, width, align) {
    return { title, key, dataIndex: key, render, width, align };
  }

  const changeArrayFilters = ({ key, value, text, title }) => {
    const isIncludes = (el, filterName) => {
      return el.key.includes(filterName) !== key.includes(filterName);
    };

    let filtersResult = filtersArray.filter((i) => {
      return isIncludes(i, "master") || isIncludes(i, "is_warranty");
    });

    setFiltersArray(
      value || value === 0
        ? [
            ...filtersResult,
            { key: key, value: value, text: text, title: title },
          ]
        : filtersResult
    );
  };

  const handleChangeDateAfter = (e) => {
    if (e?.$d) {
      setDateAfterFilter(e?.$d.toLocaleDateString("ru-RU"));
      if (dateBeforeFilter) {
        setViewDataFilter(
          e?.$d.toLocaleDateString("ru-RU") + " - " + dateBeforeFilter
        );
      } else {
        setViewDataFilter(e?.$d.toLocaleDateString("ru-RU") + " - ");
      }
    } else {
      if (dateAfterFilter) {
        setViewDataFilter(dateBeforeFilter);
      }
      setDateAfterFilter(null);
    }
  };

  const handleChangeDateBefore = (e) => {
    if (e?.$d) {
      setDateBeforeFilter(e?.$d.toLocaleDateString("ru-RU"));
      if (dateAfterFilter) {
        setViewDataFilter(
          dateAfterFilter + " - " + e?.$d.toLocaleDateString("ru-RU")
        );
      } else {
        setViewDataFilter(" - " + e?.$d.toLocaleDateString("ru-RU"));
      }
    } else {
      if (dateBeforeFilter) {
        setViewDataFilter(dateAfterFilter);
      }
      setDateBeforeFilter(null);
    }
  };

  const fetchData = (pagination = false) => {
    let filters = false;
    let offsetValue = null;
    let limitValue = null;

    if (dateAfterFilter || dateBeforeFilter) {
      filters = {
        start_date: convertDateToISO861(dateAfterFilter),
        end_date: convertDateToISO861(dateBeforeFilter),
      };
    }

    // if (pagination && nextPage) {
    //   const urlParams = new URLSearchParams(nextPage.split("?")[1]);
    //   offsetValue = urlParams.get("offset");
    // }

    if (pagination && nextPage) {
      const urlParams = new URLSearchParams(nextPage.split("?")[1]);
      limitValue = Number(urlParams.get("limit")) + 25;
    }

    dispatch(
      getFails({
        studio_y_id:
          JSON.parse(sessionStorage.getItem("studio"))?.y_id ||
          studioData?.y_id,
        filters: filters,
        filtersArray: filtersArray,
        // cursor: pagination ? offsetValue : null,
        limit: pagination ? limitValue : null,
        pagination: pagination,
      })
    );
  };

  const clearFilters = () => {
    setSelectedGuarantee(null);
    setSelectedStaff([]);
    setSelectedStatus(null);
    setSelectedType([]);
    setFileSearch("");
    setIncludeStaff(true);
    setIncludeStatus(true);
    setIncludeType(true);
    setIncludeGuarantee(true);
    setViewDataFilter(null);
    setDateBeforeFilter(null);
    setDateAfterFilter(null);
    setFiltersArray([]);
  };

  useEffect(() => {
    dispatch(clearErrorStudio());
    dispatch(clearError());
  }, []);

  useEffect(() => {
    if (studioData) {
      dispatch(getStaffDropDown({ studio_y_id: studioData?.y_id }));
    }
  }, [studioData?.y_id]);

  useEffect(() => {
    updateFiltersArr(
      "master",
      includeStaff,
      selectedStaff,
      setFiltersArray,
      filtersArray
    );
  }, [includeStaff, selectedStaff]);

  useEffect(() => {
    updateFilters(
      "is_warranty",
      includeGuarantee,
      selectedGuarantee,
      setFiltersArray,
      filtersArray
    );
  }, [includeGuarantee, selectedGuarantee]);

  useEffect(() => {
    updateFiltersArr(
      "fail_type",
      includeType,
      selectedType,
      setFiltersArray,
      filtersArray
    );
  }, [includeType, selectedType]);

  useEffect(() => {
    updateFilters(
      "is_returned",
      includeStatus,
      selectedStatus,
      setFiltersArray,
      filtersArray
    );
  }, [includeStatus, selectedStatus]);

  useEffect(() => {
    if (studioData) {
      dispatch(getTypeDropDown(studioData?.y_id));
    }
  }, [studioData?.y_id]);

  useEffect(() => {
    (async () => {
      if (needToLoad && nextPage) {
        fetchData(true);
        setNeedToLoad(false);
      }
    })();
  }, [needToLoad]);

  useEffect(() => {
    if (studioData) {
      const timerRefresh = setTimeout(fetchData, 0);
      return () => {
        clearTimeout(timerRefresh);
      };
    }
  }, [
    dateAfterFilter,
    dateBeforeFilter,
    filtersArray,
    studioData,
    success,
    successCreate,
    successEdit,
  ]);

  useEffect(() => {
    if (isLoadingFails) return;

    if (observer?.current) observer?.current.disconnect();

    let callback = function (
      entries
      //observer
    ) {
      if (
        entries[0].isIntersecting &&
        nextPage &&
        failsData?.data?.results?.length > 0
      ) {
        setNeedToLoad(true);
      }
    };
    observer.current = new IntersectionObserver(callback);
    if (lastItem?.current instanceof Element) {
      observer.current.observe(lastItem.current);
    } else {
      console.warn("lastItem.current is not a valid DOM element");
    }
  }, [isLoadingFails]);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
  }, [width]);

  return (
    <>
      <>
        <div
          style={{
            display: "flex",
            gap: width > 640 ? 12 : 9,
            position: "relative",
            paddingBottom: width > 640 ? 25 : 16,
          }}
        >
          <DatePickerPopover
            openDate={openDate}
            setOpenDate={setOpenDate}
            viewDataFilter={viewDataFilter}
            dateAfterFilter={dateAfterFilter}
            dateBeforeFilter={dateBeforeFilter}
            // handleSetDateFilters={handleSetDateFilters}
            handleChangeDateBefore={handleChangeDateBefore}
            handleChangeDateAfter={handleChangeDateAfter}
          />

          {width > 640 && (
            <Space direction="vertical" style={{ width: "100%" }}>
              <Input
                style={{ borderRadius: "2px" }}
                prefix={<SearchOutlined style={{ color: "#C1C2C9" }} />}
                value={fileSearch}
                placeholder="Поиск по телефону, имени клиента, мастеру и типу переделки"
                suffix={
                  <CloseOutlined
                    style={{
                      color: fileSearch ? "red" : "black",
                    }}
                    onClick={() => {
                      setFileSearch("");
                      setFiltersArray(
                        filtersArray.filter((v) => v.key !== "q")
                      );
                    }}
                  />
                }
                onChange={(evt) => {
                  setFileSearch(evt.target.value);
                  changeArrayFilters({
                    item: "",
                    key: "q",
                    value: evt.target.value,
                    text: evt.target.value,
                    title: "q",
                  });
                }}
              />
            </Space>
          )}

          <Space.Compact style={{ alignSelf: "flex-end" }} title="Фильтры">
            <Button
              onClick={() => {
                setOpenFiltersRow(!openFiltersRow);
              }}
              style={{
                borderRadius: "2px",
                width: width > 640 ? "151px" : "67px",
                gap: "20px",
              }}
              icon={
                <Badge count={filtersArray.length} size="small" color="blue">
                  <img
                    style={{
                      color: "#6679A9",
                    }}
                    alt="filters"
                    src={filters}
                  />
                </Badge>
              }
            >
              {width > 640 && "Фильтры"}
            </Button>
          </Space.Compact>

          {(dateAfterFilter ||
            dateBeforeFilter ||
            filtersArray?.length > 0) && (
            <Button
              onClick={clearFilters}
              style={{ borderRadius: 2, backgroundColor: "#4D7CD6" }}
              type={"primary"}
            >
              Сбросить
            </Button>
          )}
        </div>

        {openFiltersRow && (
          <>
            {width < 640 && (
              <Space
                direction="vertical"
                style={{ width: "100%", paddingBottom: 25 }}
              >
                <Input
                  style={{ borderRadius: "2px" }}
                  prefix={<SearchOutlined style={{ color: "#C1C2C9" }} />}
                  value={fileSearch}
                  placeholder="Поиск по телефону, имени клиента, мастеру и типу переделки"
                  suffix={
                    <CloseOutlined
                      style={{
                        color: fileSearch ? "red" : "black",
                      }}
                      onClick={() => {
                        setFileSearch("");
                        setFiltersArray(
                          filtersArray.filter((v) => v.key !== "q")
                        );
                      }}
                    />
                  }
                  onChange={(evt) => {
                    setFileSearch(evt.target.value);
                    changeArrayFilters({
                      item: "",
                      key: "q",
                      value: evt.target.value,
                      text: evt.target.value,
                      title: "q",
                    });
                  }}
                />
              </Space>
            )}
            <div
              style={{
                display: width > 543 ? "flex" : "grid",
                rowGap: width > 640 ? 12 : 22,
                columnGap: width > 640 ? 12 : 7,
                gridTemplateColumns: width < 543 ? "repeat(2, 50%)" : "1fr",
                position: "relative",
                paddingBottom: width > 640 ? 20 : 10,
              }}
            >
              <Space direction="vertical" style={{ width: "100%" }}>
                {isInclude(includeStaff)}
                <Select
                  allowClear={true}
                  optionFilterProp="label"
                  mode="multiple"
                  placeholder="Сотрудник"
                  style={{ width: "100%", textAlign: "left" }}
                  options={staffList?.results?.map((option) => {
                    return {
                      value: option.id,
                      label: option.y_name,
                    };
                  })}
                  dropdownRender={(menu) =>
                    selectDropdown(menu, includeStaff, setIncludeStaff)
                  }
                  value={selectedStaff}
                  onChange={setSelectedStaff}
                />
              </Space>

              <Space direction="vertical" style={{ width: "100%" }}>
                {isInclude(includeType)}
                <Select
                  allowClear={true}
                  optionFilterProp="label"
                  placeholder="Тип жалобы"
                  mode="multiple"
                  options={typeList?.results?.map((option) => {
                    return {
                      value: option.id,
                      label: option.name,
                    };
                  })}
                  style={{ width: "100%" }}
                  dropdownRender={(menu) =>
                    selectDropdown(menu, includeType, setIncludeType)
                  }
                  value={selectedType}
                  onChange={setSelectedType}
                />
              </Space>

              <Space direction="vertical" style={{ width: "100%" }}>
                {isInclude(includeGuarantee)}
                <Select
                  allowClear={true}
                  maxTagCount="responsive"
                  style={{ width: "100%", textAlign: "left" }}
                  placeholder="Гарантия"
                  value={selectedGuarantee}
                  onChange={setSelectedGuarantee}
                  dropdownRender={(menu) =>
                    selectDropdown(menu, includeGuarantee, setIncludeGuarantee)
                  }
                  options={guarantee.map((v) => {
                    return { value: v.bol, label: v.name };
                  })}
                />
              </Space>

              <Space direction="vertical" style={{ width: "100%" }}>
                {isInclude(includeStatus)}
                <Select
                  allowClear={true}
                  maxTagCount="responsive"
                  style={{ width: "100%", textAlign: "left" }}
                  placeholder="Статус"
                  value={selectedStatus}
                  onChange={setSelectedStatus}
                  dropdownRender={(menu) =>
                    selectDropdown(menu, includeStatus, setIncludeStatus)
                  }
                  options={status.map((v) => {
                    return { value: v.bol, label: v.name };
                  })}
                />
              </Space>
            </div>
          </>
        )}
      </>
      <div
        style={{
          marginBottom: 10,
          fontSize: 12,
          fontWeight: 300,
          marginLeft: 5,
        }}
      >
        Показано {failsData?.data?.results?.length || 0} из{" "}
        {failsData?.data?.count || 0}
      </div>
      <div
        style={{
          maxHeight: !openFiltersRow
            ? "calc(100vh - 200px)"
            : "calc(100vh - 255px)",
          width: "100%",
          overflowY: "auto",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {(error || errorStudio) && displayErrors(error || errorStudio)}
        <Table
          columns={columns}
          rowKey={(obj) => obj.id}
          size="small"
          pagination={false}
          loading={(isLoadingStudio || isLoadingFails) && <Spin size="large" />}
          className={`${styles.table} ${styles.table_header}`}
          dataSource={failsData?.data?.results || []}
          onRow={(record) => ({
            onClick: () => {
              setOpenFailsEdit({
                open: true,
                record: record,
              });
            },
          })}
        />
        <div ref={lastItem} style={{ minHeight: 10, maxHeight: 10 }} />
      </div>
      {openFailsCreate && <CreateFailsModal openFails={openFailsCreate} />}
      {openFailsEdit && (
        <EditFailsModal
          openFailsEdit={openFailsEdit}
          setOpenFailsEdit={setOpenFailsEdit}
        />
      )}
      {openFailsDelete.open && (
        <ModalComponent
          open={openFailsDelete.open}
          title="Вы уверены, что хотите удалить эту жалобу?"
          body={
            <Row gutter={20}>
              <Col span={12}>
                <Button
                  style={{
                    width: "100%",
                    borderRadius: 4,
                    height: 48,
                  }}
                  onClick={() => setOpenFailsDelete(false)}
                >
                  Не удалять
                </Button>
              </Col>
              <Col span={12}>
                <Button
                  style={{
                    width: "100%",
                    borderRadius: 4,
                    height: 48,
                    background: "var(--Primary-1-normal, #76CEC9)",
                  }}
                  type="primary"
                  htmlType="submit"
                  onClick={() => {
                    dispatch(
                      deleteFail({
                        studio_y_id: studioData?.y_id,
                        id: openFailsDelete.data.id,
                      })
                    ).then(() => {
                      dispatch(clearSuccess());
                      setOpenFailsDelete(false);
                    });
                  }}
                >
                  Удалить
                </Button>
              </Col>
            </Row>
          }
          onCancel={() => {
            setOpenFailsDelete(false);
          }}
        />
      )}
      {openInfo && (
        <InfoModal
          openModal={openInfo}
          setOpenModal={setOpenInfo}
          title={"Информация по жалобам"}
          text={"Тут будет информация"}
        />
      )}
    </>
  );
};
