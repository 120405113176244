import { useState, useEffect } from "react";
import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  DatePicker,
  Typography,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import displayErrors from "../../utils/displayErrors";
import {
  setOpenCorrections,
  postCorrections,
  getTypeDropDown,
  clearErrorCreate,
  clearSuccessCreate,
} from "../../store/corrections";
import {
  getTypeDropDownSelector,
  getErrorCreate,
  getCorrectionsPostLoading,
} from "../../store/corrections/selectors";
import { SuccessModalSide } from "../SuccessModalSide";
import TextArea from "antd/es/input/TextArea";
import "react-phone-input-2/lib/style.css";
import { throwFormError, convertDateCorrections } from "../../utils/utils";
import { getStudioData } from "../../store/studios/selectors";
import { getStaffDropDown } from "../../store/staff";
import { getStaffDropDownSelector } from "../../store/staff/selectors";
import { LoaderModal } from "../LoaderModal";

export const CreateCorrectionsModal = ({ openCorrections }) => {
  const [formCreateCorrections] = Form.useForm();
  const dispatch = useDispatch();
  const studio = useSelector(getStudioData);
  const isLoader = useSelector(getCorrectionsPostLoading);
  const error = useSelector(getErrorCreate);
  const masterList = useSelector(getStaffDropDownSelector);
  const typeList = useSelector(getTypeDropDownSelector);
  const [selectedType, setSelectedType] = useState([]);
  const [width, setWidth] = useState(window.innerWidth);
  const [openSuccessCreate, setOpenSuccessCreate] = useState(false);

  useEffect(() => {
    dispatch(getTypeDropDown(studio?.y_id));
    dispatch(getStaffDropDown({ studio_y_id: studio?.y_id }));
  }, []);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
  }, [width]);

  return (
    <>
      <Drawer
        styles={{
          body: { padding: 0 },
        }}
        open={openCorrections}
        onCancel={() => {
          dispatch(clearErrorCreate());
          dispatch(setOpenCorrections());
          dispatch(clearSuccessCreate());
        }}
        onClose={() => {
          dispatch(clearErrorCreate());
          dispatch(setOpenCorrections());
          dispatch(clearSuccessCreate());
        }}
        title={
          <Typography.Title style={{ margin: 0 }} level={3}>
            Добавить коррекцию
          </Typography.Title>
        }
        footer={null}
        width={500}
        maskClosable={true}
      >
        {isLoader && <LoaderModal />}
        {openSuccessCreate ? (
          <SuccessModalSide
            close={() => {
              dispatch(clearErrorCreate());
              dispatch(setOpenCorrections());
              dispatch(clearSuccessCreate());
              setOpenSuccessCreate(false);
            }}
            title="Коррекция успешно добавлена!"
          />
        ) : (
          <div
            style={{
              padding: width > 640 ? "0 64px 20px" : "0 24px 20px",
            }}
          >
            <Form
              style={{ marginTop: "20px" }}
              layout="vertical"
              form={formCreateCorrections}
              // initialValues={{}}
              onFinish={(data) => {
                data.studio = studio?.id;
                data.pub_date = convertDateCorrections(data.pub_date);
                dispatch(
                  postCorrections({ studio_y_id: studio?.y_id, params: data })
                ).then((state) => {
                  if (state?.payload?.errors) {
                    throwFormError(formCreateCorrections, state?.payload);
                  } else {
                    setOpenSuccessCreate(true);
                  }
                });
              }}
            >
              {error && displayErrors(error)}
              <Form.Item
                name="master"
                rules={[
                  {
                    required: true,
                    message: "Необходимо выбрать сотрудника",
                  },
                ]}
                label="Сотрудник"
              >
                <Select
                  style={{ borderRadius: 2 }}
                  options={masterList?.results?.map((option) => {
                    return {
                      value: option.id,
                      label: option.y_name,
                    };
                  })}
                  optionFilterProp="label"
                  showSearch={true}
                  allowClear={true}
                  value={selectedType}
                  onChange={setSelectedType}
                  placeholder="Выберите сотрудника"
                />
              </Form.Item>

              <Form.Item
                name="pub_date"
                label="Дата коррекции"
                rules={[
                  {
                    required: true,
                    message: "Необходимо выбрать дату коррекции",
                  },
                ]}
              >
                <DatePicker
                  format="DD.MM.YYYY"
                  cellWidth={10}
                  timeColumnWidth={10}
                  presetsMaxWidth={10}
                  presetsWidth={10}
                  style={{ width: "100%", borderRadius: 2 }}
                  placeholder="Выберите дату коррекции"
                />
              </Form.Item>

              <Form.Item
                name="corr_type"
                rules={[
                  {
                    required: true,
                    message: "Необходимо выбрать тип коррекции",
                  },
                ]}
                label="Тип коррекции"
              >
                <Select
                  style={{ borderRadius: 2 }}
                  options={typeList?.map((option) => {
                    return {
                      value: option.value,
                      label: option.label,
                    };
                  })}
                  value={selectedType}
                  onChange={setSelectedType}
                  placeholder="Выберите тип коррекции"
                />
              </Form.Item>

              <Form.Item
                name="cost"
                label="Сумма коррекции"
                rules={[
                  {
                    required: true,
                    message: "Необходимо ввести сумму коррекции",
                  },
                ]}
              >
                <Input
                  placeholder="Введите сумму коррекции"
                  style={{ borderRadius: 2 }}
                />
              </Form.Item>

              <Form.Item name="comment" label="Комментарий">
                <TextArea
                  placeholder="Описание"
                  autoSize={{
                    minRows: 3,
                    maxRows: 5,
                  }}
                  style={{ borderRadius: 2 }}
                />
              </Form.Item>

              <Row gutter={24}>
                <Col span={12}>
                  <Button
                    style={{ width: "100%", borderRadius: 2 }}
                    onClick={() => {
                      dispatch(setOpenCorrections());
                      dispatch(clearErrorCreate());
                    }}
                  >
                    Отмена
                  </Button>
                </Col>
                <Col span={12}>
                  <Button
                    style={{
                      width: "100%",
                      borderRadius: 2,
                      backgroundColor: "#76CEC9",
                    }}
                    htmlType="submit"
                    type="primary"
                  >
                    Добавить
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        )}
      </Drawer>
    </>
  );
};
