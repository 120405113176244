import { useState, useEffect } from "react";
import styles from "./styles.module.css";
import User from "../../images/user.svg";
import ArrowBottom from "../../images/arrowBottom.svg";
import { Button, Typography } from "antd";
import imgBurger from "../../images/iconBurger.svg";
import { MenuProfile } from "../MenuProfile";
import { useDispatch, useSelector } from "react-redux";
import { getUserData } from "../../store/user/selectors";
import { setOpenFails } from "../../store/fails";
import { setOpenCorrections } from "../../store/corrections";
import { setOpenUserManagement } from "../../store/userManagement";
import { setOpenType } from "../../store/typeComplaints";
import {
  setOpenPlanningCreate,
  setOpenPlanningEdit,
} from "../../store/planning";
import { setOpenInfo } from "../../store/user";
import { setOpenPayments } from "../../store/payments";
import { getOpenPaymentsSelector } from "../../store/payments/selectors";
import { setOpenCalculation } from "../../store/calculation";
import { getOpenCalculationSelector } from "../../store/calculation/selectors";
import { setOpenCalculationNetwork } from "../../store/networkCalculation";
import { getOpenCalculationNetworkSelector } from "../../store/networkCalculation/selectors";
import iconPlus from "../../images/iconPlus.svg";
import iconMoney from "../../images/iconMoney.svg";
import Help from "../../images/iconInfo.svg";
import { setOpenSidebar } from "../../store/user";
import { getPlanningData } from "../../store/planning/selectors";

export const Header = ({ pageId, openSidebar, width }) => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const userData = useSelector(getUserData);
  const isOpenPayments = useSelector(getOpenPaymentsSelector);
  const isOpenCalculation = useSelector(getOpenCalculationSelector);
  const isOpenCalculationNetwork = useSelector(
    getOpenCalculationNetworkSelector
  );
  const planningData = useSelector(getPlanningData);

  return (
    <div className={styles.header}>
      <div className={styles.headerBlock}>
        <div className={styles.containerHeader}>
          <div className={styles.container}>
            {width > 640 && (
              <Button
                htmlType="button"
                onClick={() => dispatch(setOpenSidebar(!openSidebar))}
                className={styles.buttonSidebar}
              >
                <img src={imgBurger} alt="burger" />
              </Button>
            )}
            <Typography.Text className={styles.text}>
              {pageId === "complaints" && "Список жалоб"}
              {pageId === "corrections" && "Список коррекций"}
              {pageId === "settings" && "Настройки"}
              {pageId === "user" && "Пользователи сетевого доступа"}
              {pageId === "payments" && "Выплаты"}
              {pageId === "calculation" && "Расчет"}
              {pageId === "calculationNetwork" && "Сетевой расчет"}
              {pageId === "complaintsNetwork" && "Список жалоб сети"}
              {pageId === "correctionsNetwork" && "Список коррекций сети"}
              {pageId === "types" && "Типы жалоб клиентов"}
              {pageId === "staff" && "Сотрудники"}
              {pageId === "staff_create" && "Новый локальный сотрудник"}
              {pageId === "staff_edit" && "Сотрудник"}
              {pageId === "staff_network" && "Сетевые сотрудники"}
              {pageId === "staff_network_edit" && "Сетевой сотрудник"}
              {pageId === "admin" && "Настройки ошибок админа"}
              {pageId === "sattings_planning" && "Настройки планирования"}
              {pageId === "planning" && "Планирование"}
              {pageId === "expenses" && "Сетевые расходы"}
            </Typography.Text>
          </div>
          <Button
            htmlType="button"
            className={styles.buttonHeader}
            onClick={() => setOpen(!open)}
          >
            {width > 640 && (
              <Typography.Text className={styles.textUser}>
                {userData?.external_data?.y_name || userData?.user?.username}
              </Typography.Text>
            )}
            <img src={User} alt="Пользователь" />
            <img src={ArrowBottom} alt="Стрелка" className={styles.arrow} />
          </Button>
          {open && <MenuProfile setOpen={setOpen} />}
        </div>
        <img
          src={Help}
          alt="info"
          className={styles.iconHelp}
          onClick={() => dispatch(setOpenInfo())}
        />
      </div>
      {pageId === "complaints" && (
        <Button
          type="primary"
          htmlType="button"
          className={styles.button}
          onClick={() => dispatch(setOpenFails())}
        >
          <img src={iconPlus} alt="plus" />
          {width > 640 && (
            <span className={styles.button_text}>Новая жалоба</span>
          )}
        </Button>
      )}
      {pageId === "corrections" && (
        <Button
          type="primary"
          htmlType="button"
          className={styles.button}
          onClick={() => dispatch(setOpenCorrections())}
        >
          <img src={iconPlus} alt="plus" />
          {width > 640 && (
            <span className={styles.button_text}>Новая корреция</span>
          )}
        </Button>
      )}
      {pageId === "user" && (
        <Button
          type="primary"
          htmlType="button"
          className={styles.button}
          onClick={() => dispatch(setOpenUserManagement())}
        >
          <img src={iconPlus} alt="plus" />
          {width > 640 && <span className={styles.button_text}>Добавить</span>}
        </Button>
      )}
      {pageId === "payments" && !isOpenPayments && (
        <Button
          type="primary"
          htmlType="button"
          className={styles.button}
          onClick={() => dispatch(setOpenPayments())}
        >
          <img src={iconMoney} alt="money" />
          {width > 640 && (
            <span className={styles.button_text}>Внести в YClients</span>
          )}
        </Button>
      )}
      {pageId === "calculation" &&
        (!isOpenCalculation ? (
          <Button
            type="primary"
            htmlType="button"
            className={styles.button}
            onClick={() => dispatch(setOpenCalculation())}
          >
            <img src={iconPlus} alt="plus" />
            {width > 640 && (
              <span className={styles.button_text}>Оформить выплаты</span>
            )}
          </Button>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 10,
            }}
          >
            <Button
              type="primary"
              htmlType="button"
              className={styles.button}
              style={{ backgroundColor: "#F07878" }}
              onClick={() => dispatch(setOpenCalculation())}
            >
              <img src={iconPlus} alt="plus" />
              {width > 640 && (
                <span style={{ width: "100%" }} className={styles.button_text}>
                  Отмена
                </span>
              )}
            </Button>
            <Button
              type="primary"
              htmlType="button"
              className={styles.button}
              style={{ backgroundColor: "#4D7CD6" }}
              onClick={() => dispatch(setOpenCalculation())}
            >
              <img src={iconPlus} alt="plus" />
              {width > 640 && (
                <span className={styles.button_text}>Создать выплаты</span>
              )}
            </Button>
          </div>
        ))}
      {pageId === "calculationNetwork" &&
        (!isOpenCalculationNetwork ? (
          <Button
            type="primary"
            htmlType="button"
            className={styles.button}
            onClick={() => dispatch(setOpenCalculationNetwork())}
          >
            <img src={iconPlus} alt="plus" />
            {width > 640 && (
              <span className={styles.button_text}>Оформить выплаты</span>
            )}
          </Button>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 10,
            }}
          >
            <Button
              type="primary"
              htmlType="button"
              className={styles.button}
              style={{ backgroundColor: "#F07878" }}
              onClick={() => dispatch(setOpenCalculationNetwork())}
            >
              <img src={iconPlus} alt="plus" />
              {width > 640 && (
                <span style={{ width: "100%" }} className={styles.button_text}>
                  Отмена
                </span>
              )}
            </Button>
            <Button
              type="primary"
              htmlType="button"
              className={styles.button}
              style={{ backgroundColor: "#4D7CD6" }}
              onClick={() => dispatch(setOpenCalculationNetwork())}
            >
              <img src={iconPlus} alt="plus" />
              {width > 640 && (
                <span className={styles.button_text}>Создать выплаты</span>
              )}
            </Button>
          </div>
        ))}
      {pageId === "types" && (
        <Button
          type="primary"
          htmlType="button"
          className={styles.button}
          onClick={() => dispatch(setOpenType())}
        >
          <img src={iconPlus} alt="plus" />
          {width > 640 && <span className={styles.button_text}>Добавить</span>}
        </Button>
      )}
      {pageId === "staff_edit" && (
        <div style={{ width: 204, height: 36 }}></div>
      )}
      {pageId === "planning" &&
        planningData &&
        (planningData?.summary?.plan === 0 ? (
          <Button
            type="primary"
            htmlType="button"
            className={styles.button}
            onClick={() => dispatch(setOpenPlanningCreate())}
          >
            <img src={iconPlus} alt="plus" />
            {width > 640 && (
              <span className={styles.button_text}>Установить план</span>
            )}
          </Button>
        ) : (
          <Button
            type="primary"
            htmlType="button"
            className={styles.button}
            style={{ backgroundColor: "#4D7CD6" }}
            onClick={() => dispatch(setOpenPlanningEdit())}
          >
            <img src={iconPlus} alt="plus" />
            {width > 640 && (
              <span className={styles.button_text}>Изменить план</span>
            )}
          </Button>
        ))}
    </div>
  );
};
