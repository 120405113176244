import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "../../utils/axios";
import { logout } from "../login";

const checkFilters = (filters, filtersArray, cursor = false, limit = false) => {
  if (!filters) filters = {};

  if (filtersArray?.length > 0) {
    filtersArray?.map((item) => {
      if (item.key !== "start_date" && item.key !== "end_date") {
        if (Array.isArray(item.value)) {
          filters[item.key] = item.value.join(",");
        } else {
          filters[item.key] = item.value;
        }
      } else "";
    });
  }

  if (cursor !== false) {
    filters.offset = cursor;
  }

  if (limit !== false) {
    filters.limit = limit;
  }

  return filters;
};

export const getSettingsComplaints = createAsyncThunk(
  "getSettingsComplaints",
  async (
    { studio_y_id, filters, filtersArray, limit, cursor, pagination },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await axiosInstance.get(
        `/network_studio_settings/studio/${studio_y_id}/settings/`,
        {
          params: checkFilters(filters, filtersArray, cursor, limit),
        }
      );
      return { data, pagination };
    } catch (err) {
      if (err.code === "ERR_NETWORK") return rejectWithValue(err);
      else return rejectWithValue(err.response);
    }
  }
);

export const getUserManagement = createAsyncThunk(
  "getUserManagement",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get("/user-context/data/");
      return data;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const patchUserManagement = createAsyncThunk(
  "patchUserManagement",
  async ({ studio_y_id, id, users }, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.patch(
        `/network_studio_settings/studio/${studio_y_id}/settings/${id}/`,
        users
      );
      return data;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const deleteUserManagement = createAsyncThunk(
  "deleteUserManagement",
  async ({ studio_y_id, id, user_id }, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.delete(
        `/network_studio_settings/studio/${studio_y_id}/settings/${id}/delete_user/${user_id}/`
      );
      return data;
    } catch (err) {
      if (err.response.status === 401) dispatch(logout());
      return rejectWithValue(err.response.data);
    }
  }
);

const initialState = {
  settingsComplaints: null,
  user: null,
  errors: {
    error: null,
    delete: null,
    patch: null,
  },
  success: { delete: false, patch: false },
  openUserManagement: false,
  loaders: {
    common: false,
    patchDelete: false,
  },
};

const userManagementSlice = createSlice({
  name: "userManagement",
  initialState,
  reducers: {
    setOpenUserManagement: (state) => {
      state.openUserManagement = !state.openUserManagement;
    },
    clearSuccessDelete: (state) => {
      state.success.delete = false;
    },
    clearSuccessPatch: (state) => {
      state.success.patch = false;
    },
  },

  extraReducers: (builder) => {
    // getSettingsComplaints
    builder.addCase(getSettingsComplaints.pending, (state) => {
      state.loaders.common = true;
      state.errors.error = null;
      state.nextPage = null;
    });
    builder.addCase(getSettingsComplaints.fulfilled, (state, { payload }) => {
      state.loaders.common = false;
      state.settingsComplaints = payload.data.results;
      state.nextPage = payload?.data.next;
    });
    builder.addCase(getSettingsComplaints.rejected, (state, action) => {
      state.loaders.common = false;
      state.errors.error = action?.payload?.data;
    });
    // getUser
    builder.addCase(getUserManagement.pending, (state) => {
      state.errors = null;
    });
    builder.addCase(getUserManagement.fulfilled, (state, { payload }) => {
      state.user = payload;
    });
    builder.addCase(getUserManagement.rejected, (state, action) => {
      state.errors = action?.payload?.data;
    });
    // patchUserManagement
    builder.addCase(patchUserManagement.pending, (state) => {
      state.loaders.patchDelete = true;
      state.errors.patch = null;
    });
    builder.addCase(patchUserManagement.fulfilled, (state) => {
      state.loaders.patchDelete = false;
      state.success.patch = true;
    });
    builder.addCase(patchUserManagement.rejected, (state, action) => {
      state.loaders.patchDelete = false;
      state.errors.patch = action?.payload;
    });
    // deleteUserManagement
    builder.addCase(deleteUserManagement.pending, (state) => {
      state.loaders.patchDelete = true;
      state.errors.delete = null;
    });
    builder.addCase(deleteUserManagement.fulfilled, (state) => {
      state.loaders.patchDelete = false;
      state.success.delete = true;
    });
    builder.addCase(deleteUserManagement.rejected, (state, action) => {
      state.loaders.patchDelete = false;
      state.errors.delete = action?.payload;
    });
  },
});
export const { setOpenUserManagement, clearSuccessDelete, clearSuccessPatch } =
  userManagementSlice.actions;
export default userManagementSlice.reducer;
